// extracted by mini-css-extract-plugin
export var background_images = "contactInfo-module__background_images___A3VzQ";
export var body_background = "#f8f9fa";
export var card = "contactInfo-module__card___dBc2g";
export var chip = "contactInfo-module__chip___wz9Kk";
export var contact_info = "contactInfo-module__contact_info___4SnLQ";
export var container = "contactInfo-module__container___XjMp6";
export var content = "contactInfo-module__content___MNHwW";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "contactInfo-module__full_img___7bNhY";
export var full_imgSmall = "contactInfo-module__full_img--small___zW8aB";
export var gray_21 = "contactInfo-module__gray_21___1SR8i";
export var image_container = "contactInfo-module__image_container___kxk9z";
export var image_item = "contactInfo-module__image_item___QfG6b";
export var lg = "1200px";
export var logo = "contactInfo-module__logo___kNX9z";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "contactInfo-module__primary___8qEE5";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "contactInfo-module__title___2Oh7j";
export var white = "#fff";
export var white_button = "contactInfo-module__white_button___SIUNt";
export var xl = "1536px";
export var xxl = "2500px";
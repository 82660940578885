import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { injectIntl } from 'react-intl';
import { Grid } from '@mui/material';

import * as styles from './contactInfo.module.scss';
import { Card } from '@components';

const ContactInfo = ({ intl: { formatMessage } }) => {
  return (
    <div className={styles.contact_info}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Card
            image={
              <StaticImage
                src="../../../images/contact_info_1.webp"
                alt="Contact info 1"
                sizes="(min-width: 54px) 54px, (min-width: 76px) 76px"
              />
            }
            title={formatMessage({ id: 'contactInfo1Title' })}
            content={
              <a href="mailto:info@connectingtechpeople.com">
                info@connectingtechpeople.com
              </a>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card
            image={
              <StaticImage
                src="../../../images/contact_info_2.webp"
                alt="Contact info 2"
                sizes="(min-width: 54px) 54px, (min-width: 76px) 76px"
              />
            }
            title={formatMessage({ id: 'contactInfo2Title' })}
            content={
              <a href="https://wa.me/34643916329">
                (+34) 643 91 63 29
              </a>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card
            image={
              <StaticImage
                src="../../../images/contact_info_3.webp"
                alt="Contact info 3"
                sizes="(min-width: 54px) 54px, (min-width: 76px) 76px"
              />
            }
            title={formatMessage({ id: 'contactInfo3Title' })}
            content={formatMessage({ id: 'contactInfo3Subtitle' })}
            subtitle={
              <a href="tel:+34643916329">
                (+34) 643 91 63 29
              </a>
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

ContactInfo.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ContactInfo);

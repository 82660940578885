import React from 'react';
import { Banner, ContactInfo } from '@contact';
const ContactPage = () => {
  return (
    <main>
      <Banner />
      <ContactInfo />
    </main>
  );
};

export default ContactPage;
